export const apiEndPoints = {
  // Subjects
  getSubject: "/subjects/getSubjects",
  addSubject: "/subjects/addSubjects",
  deleteSubject: "/subjects/deleteSubjects",
  // Video
  getVideos: "/video/getvideo",
  addVideo: "/video/addVideo",
  deleteVideo: "/video/videoDelete",
  // Study Material
  getStudyMaterial: "/studyMaterial/getSubjectTopics",
  getStudyMaterialbyid: "/studyMaterial/getSubjectTopic",
  addStudyMaterial: "/studyMaterial/addSubjectTopics",
  deleteStudyMaterial: "/studyMaterial/deleteSubjectTopics",
  editStudyMaterial: "/studyMaterial/editSubjectTopics",
  // Current affair
  getCurrentAffairs: "/currentAffairs/getallCurrentAffairs",
  addCurrentAffairs: "/currentAffairs/addCurrentAffairs",
  deleteCurrentAffairs: "/currentAffairs/deleteCurrentAffairs",
  // Blogs
  fetchBlogs: "/blogs/getBlogs",
  addBlog: "/blogs/addBlog",
  deleteBlog: "/blogs/deleteBlog",
  // Whats New
  fetchWhatsNew: "/whatsNew/getWhatsNew",
  addWhatsNew: "/whatsNew/addWhatsNew",
  deleteWhatsNew: "/whatsNew/deleteWhatsNew",
  // Daily Vocab
  fetchDailyVocab: "/dalyVocab/getDalyVocab",
  addDailyVocab: "/dalyVocab/addDalyVocab",
  deleteDailyVocab: "/dalyVocab/deleteDalyVocab",
  // E Book
  fetchEBook: "/books/getBooks",
  addEBook: "/books/addBook",
  deleteEBook: "/books/deleteBook",
  // Job
  fetchJob: "/job/getJob",
  updateJob: "/job/updateJob",
  addJob: "/job/addJob",
  deleteJob: "/job/deleteJob",
  // Rwview
  getReviews: "/review/getReviews",
  getreviewnyid: "/review/getreviewbyid",
  addReview: "/review/addReview",
  editReview: "/review/editReview",
  deleteReview: "/review/reviewDelete",
  // Question
  addQuestion: "/question/addQuestion",
  getQustions: "/question/",
  editQuestion: "/question/editQuestion",
  deleteQuestion: "/question/deleteQuestion",
  getgetQuestionsListbySubjectid: "/question/getQuestionsListbySubjectid",
  // Quiz
  getQuiz: "/quiz/getQuizs",
  addQuiz: "/quiz/addQuiz",
  editQuiz: "/quiz/updateQuiz",
  deleteQuiz: "/quiz/deleteQuiz",
  //user
  addUser: "/user/addUser",
  getUser: "/users/getAllUsers",
  editUser: "/users/editUser",
  deleteUser: "/users/deleteUser",
};
